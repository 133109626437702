import { Box, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import {
  Cadence,
  CompensationPayments,
  DateOfFirstPayment,
  EqualPaymentAmount,
  ExpensesToBeCovered,
  JointHouseholdAccountBank,
} from '#/components/pages/CohabitationAgreement/steps/LifeSituation/joint-questions';
import useLocales from '#/hooks/useLocales';
import { RHFTextField } from '#/components/shared/hook-form';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';
import useAuth from '#/hooks/useAuth';

const compensationOptions = [
  {
    value: 'weekly',
  },
  {
    value: 'bi_weekly',
  },
  {
    value: 'monthly',
  },
  {
    value: 'bi_monthly',
  },
  {
    value: 'quarterly',
  },
];

export default function OneFullTimeOnePartTime() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { watch } = useFormContext();

  const whoWorksFullTime = watch('who_works_full_time');
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginTop: 3,
      }}
    >
      <RHFStyledRadioGroup
        name="who_works_full_time"
        question={{
          questionKey: 'who_works_full_time',
          question: String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.who_works_full_time'
            )
          ),
          answers: [
            {
              value: 'your_partner',
              label: String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.your_partner',
                  {
                    partnersGender: translate(
                      `cohabitationAgreement.stepOne.${
                        user?.gender === 'M' ? 'femalePartner' : 'malePartner'
                      }`
                    ),
                  }
                )
              ),
            },
            {
              value: 'you',
              label: String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.you'
                )
              ),
            },
          ],
        }}
      />
      <Stack spacing={1}>
        <Typography variant="subtitle1">
          {whoWorksFullTime === 'you'
            ? String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.yourWorkload'
                )
              )
            : String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.partnersWorkload',
                  {
                    partnersGender: translate(
                      `cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.${
                        user?.gender === 'F' ? 'malePartner' : 'femalePartner'
                      }`
                    ),
                  }
                )
              )}
        </Typography>
        <RHFTextField
          name="workload_distribution"
          label={String(translate('global.formLabels.workload'))}
        />
      </Stack>
      <JointHouseholdAccountBank />

      <Stack spacing={1}>
        <Typography variant="subtitle1">
          {String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.equal_payment'
            )
          )}
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <EqualPaymentAmount />
            <RHFTextField
              name="payment_ratio_per_party"
              label={String(translate('global.formLabels.shareYouPay'))}
            />
            <Cadence name="equal_payment_frequency" />
          </Stack>
          <DateOfFirstPayment />
        </Stack>
      </Stack>

      <CompensationPayments showSharePayment options={compensationOptions} />
      <ExpensesToBeCovered />

      <Stack spacing={1}>
        <Typography variant="subtitle1">
          {String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.account_balance_in_case_of_separation'
            )
          )}
        </Typography>
        <RHFTextField
          name="account_balance_in_case_of_separation"
          label={String(
            translate(
              `global.formLabels.${
                user?.gender === 'F'
                  ? 'shareForMalePartner'
                  : 'shareForFemalePartner'
              }`
            )
          )}
        />
      </Stack>
    </Box>
  );
}
