import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import InventoryForm from '#/components/pages/CohabitationAgreement/steps/Inventory/sole-ownership/inventory-form';
import { useGetInventoryList } from '#/api/servicesQueries';
import InventoryDetails from '#/components/pages/CohabitationAgreement/steps/Inventory/sole-ownership/inventory-details';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

export default function SoleOwnership() {
  const [addNewItem, setAddNewItem] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<number | boolean>(false);

  const { translate } = useLocales();
  const handleAddNewItem = () => setAddNewItem(true);
  const handleEditItem = (id: number) => setEditItem(id);

  const { data: inventoryList } = useGetInventoryList(false);

  const isInitial = inventoryList?.results?.length === 0;

  return (
    <Box>
      <Typography variant="subtitle1">
        {String(translate('cohabitationAgreement.stepThree.soleOwnershipList'))}
      </Typography>
      {isInitial || addNewItem || editItem ? (
        <InventoryForm
          resetAddNew={() => setAddNewItem(false)}
          resetEdit={() => setEditItem(false)}
          isAddNew={addNewItem}
          isEdit={!!editItem}
          inventoryItemID={Number(editItem)}
        />
      ) : (
        <>
          {inventoryList?.results?.map((item) => (
            <InventoryDetails
              key={item.id}
              {...item}
              editItem={handleEditItem}
            />
          ))}
          <Button
            onClick={handleAddNewItem}
            variant="contained"
            sx={{ marginTop: 1 }}
            startIcon={<Iconify icon="bi:plus" />}
          >
            <Typography>{String(translate('global.addNew'))}</Typography>
          </Button>
        </>
      )}
    </Box>
  );
}
