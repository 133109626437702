import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';

type Props = {
  owner: string;
  inventoryList: {
    results: any[];
  };
};

export default function OwnershipBenefiter({ owner, inventoryList }: Props) {
  const { translate } = useLocales();
  return (
    <View style={styles.section}>
      <Text>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.inventory.ownershipBenefiter',
            {
              owner,
            }
          )
        )}
      </Text>
      {inventoryList?.results?.map((item, i) => (
        <Text key={i}>• {item.description_good}</Text>
      ))}
      <Text>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.inventory.otherPersonals'
          )
        )}
      </Text>
    </View>
  );
}
