import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

type OutputProps = {
  sectionNo: number;
  city: string;
};

export default function DissolutionOutput({ sectionNo, city }: OutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        {sectionNo}
        {` ${String(
          translate('cohabitationAgreement.stepFour.document.dissolution.title')
        )}`}
      </Text>
      <Text>
        {String(
          translate(
            `cohabitationAgreement.stepFour.document.dissolution.firstText`
          )
        )}
      </Text>
      <Text>
        {String(
          translate(
            `cohabitationAgreement.stepFour.document.dissolution.secondText`,
            {
              city,
            }
          )
        )}
      </Text>
    </View>
  );
}
