import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';

type SignatureOutputProps = {
  user: string;
  address: string;
  partner: string;
  partnerAdress: string;
};

export default function SignatureOutput({
  user,
  partner,
  address,
  partnerAdress,
}: SignatureOutputProps) {
  return (
    <View style={styles.section2}>
      <View
        style={{
          marginTop: 40,
        }}
      >
        <Text>
          {address}, {fDate(new Date())}
        </Text>
        <Text
          style={{
            marginTop: 15,
          }}
        >
          {user}
        </Text>
      </View>
      <View
        style={{
          marginTop: 40,
        }}
      >
        <Text>
          {partnerAdress}, {fDate(new Date())}
        </Text>
        <Text
          style={{
            marginTop: 15,
          }}
        >
          {partner}
        </Text>
      </View>
    </View>
  );
}
