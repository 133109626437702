import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';
import { formatWithThousandSeparator } from '#/utils/formatNumber';

type CostOfLivingOutputProps = {
  situation: string;
  firstParty: string;
  secondParty: string;
  compensation_payment_cadence: string;
  equal_payment_amount: string;
  equal_payment_frequency: string;
  date_of_first_payment: string;
  fullTimeWorker: string;
  partTimeWorker: string;
  expenses_to_be_covered: string[];
  percentagePaidByMe: number;
  percentagePaidByPartner: number;
  amountPaidByMe: number;
  amountPaidByPartner: number;
  monthly_transfer_for_free_disposal: string;
  free_disposal_amount: string;
  userGender: string;
  compensationPaidByMe: number;
  compensationPaidByPartner: number;
  paidByMeInCaseOfSeparation: number;
  paidByPartnerInCaseOfSeparation: number;
};

const expensesOptions = [
  {
    value: 'rent',
  },
  {
    value: 'additional',
  },
  {
    value: 'radioTV',
  },
  {
    value: 'groceries',
  },
  {
    value: 'cleaning',
  },
];

export default function CostOfLivingOutput({
  situation,
  firstParty,
  secondParty,
  compensation_payment_cadence,
  equal_payment_amount,
  equal_payment_frequency,
  date_of_first_payment,
  fullTimeWorker,
  partTimeWorker,
  expenses_to_be_covered,
  percentagePaidByMe,
  percentagePaidByPartner,
  amountPaidByMe,
  amountPaidByPartner,
  monthly_transfer_for_free_disposal,
  free_disposal_amount,
  userGender,
  compensationPaidByMe,
  compensationPaidByPartner,
  paidByMeInCaseOfSeparation,
  paidByPartnerInCaseOfSeparation,
}: CostOfLivingOutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        3{' '}
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.costOfLiving.title'
          )
        )}
      </Text>
      {situation === 'both_employed_no_children' && (
        <BothEmployedNoChildren
          firstParty={firstParty}
          secondParty={secondParty}
          compensation_payment_cadence={compensation_payment_cadence}
          equal_payment_amount={equal_payment_amount}
          equal_payment_frequency={equal_payment_frequency}
          date_of_first_payment={date_of_first_payment}
          percentagePaidByMe={percentagePaidByMe}
          percentagePaidByPartner={percentagePaidByPartner}
        />
      )}
      {situation === 'one_full_time_one_part_time_no_children' && (
        <OneWorksFullTime
          date_of_first_payment={date_of_first_payment}
          compensation_payment_cadence={compensation_payment_cadence}
          equal_payment_frequency={equal_payment_frequency}
          firstParty={firstParty}
          secondParty={secondParty}
          percentagePaidByMe={percentagePaidByMe}
          percentagePaidByPartner={percentagePaidByPartner}
          compensationPaidByMe={compensationPaidByMe}
          compensationPaidByPartner={compensationPaidByPartner}
          amountPaidByMe={amountPaidByMe}
          amountPaidByPartner={amountPaidByPartner}
        />
      )}
      {situation === 'one_full_time_one_household_with_children' && (
        <OneEmployedWithChildren
          compensation_payment_cadence={compensation_payment_cadence}
          date_of_first_payment={date_of_first_payment}
          equal_payment_amount={equal_payment_amount}
          equal_payment_frequency={equal_payment_frequency}
          fullTimeWorker={fullTimeWorker}
        />
      )}
      <Text
        style={{
          ...styles.title,
          marginVertical: 5,
        }}
      >
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.costOfLiving.section2.title'
          )
        )}
      </Text>
      {expenses_to_be_covered?.map((expense, i) => (
        <Text
          key={expense}
          style={{
            ...(i === expenses_to_be_covered.length - 1 && { marginBottom: 5 }),
          }}
        >
          •{' '}
          {expensesOptions.find((e) => e.value === expense)
            ? String(
                translate(`cohabitationAgreement.expensesOptions.${expense}`)
              )
            : expense}
        </Text>
      ))}
      {situation === 'both_employed_no_children' && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.costOfLiving.section2.bothEmployedNoChildren'
            )
          )}
        </Text>
      )}
      {situation === 'one_full_time_one_part_time_no_children' && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.costOfLiving.section2.oneWorksFullTime',
              {
                who_works_full_time: fullTimeWorker,
                who_works_part_time: partTimeWorker,
                percentage_paid_by_me: paidByMeInCaseOfSeparation,
                percentage_paid_by_partner: paidByPartnerInCaseOfSeparation,
                partnerGender:
                  userGender === 'M'
                    ? translate(
                        'cohabitationAgreement.stepFour.document.costOfLiving.section2.femalePartner'
                      )
                    : translate(
                        'cohabitationAgreement.stepFour.document.costOfLiving.section2.malePartner'
                      ),
              }
            )
          )}
        </Text>
      )}
      {situation === 'one_full_time_one_household_with_children' && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.costOfLiving.section2.oneFullTimeOneHousehold.firstText',
              {
                household: partTimeWorker,
              }
            )
          )}{' '}
          {monthly_transfer_for_free_disposal === 'yes' &&
            String(
              translate(
                'cohabitationAgreement.stepFour.document.costOfLiving.section2.oneFullTimeOneHousehold.isFreeDisposal',
                {
                  who_works_full_time: fullTimeWorker,
                  household: partTimeWorker,
                  free_disposal_amount,
                }
              )
            )}
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.costOfLiving.section2.oneFullTimeOneHousehold.thirdText',
              {
                household: partTimeWorker,
              }
            )
          )}
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.costOfLiving.section2.oneFullTimeOneHousehold.fourthText'
            )
          )}
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.costOfLiving.section2.oneFullTimeOneHousehold.fifthText'
            )
          )}
        </Text>
      )}
    </View>
  );
}

type BothEmployedNoChildrenProps = {
  firstParty: string;
  secondParty: string;
  compensation_payment_cadence: string;
  equal_payment_amount: string;
  equal_payment_frequency: string;
  date_of_first_payment: string;
  percentagePaidByMe: number;
  percentagePaidByPartner: number;
};

const BothEmployedNoChildren = ({
  firstParty,
  secondParty,
  compensation_payment_cadence,
  equal_payment_amount,
  equal_payment_frequency,
  date_of_first_payment,
  percentagePaidByMe,
  percentagePaidByPartner,
}: BothEmployedNoChildrenProps) => {
  const { translate } = useLocales();
  return (
    <Text>
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.bothEmployedNoChildren.firstText',
          {
            firstParty,
            secondParty,
            equal_payment_amount: formatWithThousandSeparator(
              String(equal_payment_amount)
            ),
            equal_payment_frequency: String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${equal_payment_frequency}`
              )
            ).toLowerCase(),
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.bothEmployedNoChildren.secondText',
          {
            date_of_first_payment: fDate(
              date_of_first_payment,
              'dd. MMM yyyy',
              'de'
            ),
            percenteage_paid_by_me: percentagePaidByMe,
            percenteage_paid_by_partner: percentagePaidByPartner,
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.bothEmployedNoChildren.thirdText',
          {
            compensation_payment_cadence: String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${compensation_payment_cadence}`
              )
            ).toLowerCase(),
          }
        )
      )}
    </Text>
  );
};

type OneWorksFullTimeProps = {
  firstParty: string;
  secondParty: string;
  compensation_payment_cadence: string;
  equal_payment_frequency: string;
  date_of_first_payment: string;
  percentagePaidByMe: number;
  percentagePaidByPartner: number;
  amountPaidByMe: number;
  amountPaidByPartner: number;
  compensationPaidByMe: number;
  compensationPaidByPartner: number;
};

const OneWorksFullTime = ({
  firstParty,
  secondParty,
  equal_payment_frequency,
  date_of_first_payment,
  compensation_payment_cadence,
  percentagePaidByMe,
  percentagePaidByPartner,
  amountPaidByMe,
  amountPaidByPartner,
  compensationPaidByMe,
  compensationPaidByPartner,
}: OneWorksFullTimeProps) => {
  const { translate } = useLocales();

  return (
    <Text>
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneWorksFullTime.firstText',
          {
            firstParty,
            secondParty,
            equal_payment_frequency: String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${equal_payment_frequency}`
              )
            ).toLowerCase(),
            amount_paid_by_me: formatWithThousandSeparator(
              String(amountPaidByMe)
            ),
            amount_paid_by_partner: formatWithThousandSeparator(
              String(amountPaidByPartner)
            ),
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneWorksFullTime.secondText',
          {
            percentage_paid_by_me: percentagePaidByMe,
            percentage_paid_by_partner: percentagePaidByPartner,
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneWorksFullTime.thirdText',
          {
            date_of_first_payment: fDate(
              date_of_first_payment,
              'dd. MMM yyyy',
              'de'
            ),
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneWorksFullTime.fourthText',
          {
            compensation_payment_cadence: String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${compensation_payment_cadence}`
              )
            ).toLowerCase(),
            percentage_paid_by_me: compensationPaidByMe,
            percentage_paid_by_partner: compensationPaidByPartner,
          }
        )
      )}
    </Text>
  );
};

type OneEmployedWithChildrenProps = {
  fullTimeWorker: string;
  equal_payment_amount: string;
  equal_payment_frequency: string;
  date_of_first_payment: string;
  compensation_payment_cadence: string;
};

const OneEmployedWithChildren = ({
  fullTimeWorker,
  equal_payment_amount,
  equal_payment_frequency,
  date_of_first_payment,
  compensation_payment_cadence,
}: OneEmployedWithChildrenProps) => {
  const { translate } = useLocales();
  return (
    <Text>
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneFullTimeOneHousehold.firstText',
          {
            who_works_full_time: fullTimeWorker,
            equal_payment_amount: formatWithThousandSeparator(
              String(equal_payment_amount)
            ),
            equal_payment_frequency: String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${equal_payment_frequency}`
              )
            ).toLowerCase(),
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneFullTimeOneHousehold.secondText',
          {
            date_of_first_payment: fDate(
              date_of_first_payment,
              'dd. MMM yyyy',
              'de'
            ),
          }
        )
      )}{' '}
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.costOfLiving.section1.oneFullTimeOneHousehold.thirdText',
          {
            compensation_payment_cadence: String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${compensation_payment_cadence}`
              )
            ).toLowerCase(),
            who_works_full_time: fullTimeWorker,
          }
        )
      )}
    </Text>
  );
};
