import { Text, View } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';

export default function OwnershipOutput() {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        2{' '}
        {String(
          translate('cohabitationAgreement.stepFour.document.ownership.title')
        )}
      </Text>
      <Text style={styles.title}>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.ownership.section1.text'
          )
        )}
      </Text>
    </View>
  );
}
