import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

type OutputProps = {
  sectionNo: number;
  childrenNo: number;
};

export default function CirsumstancesChange({
  sectionNo,
  childrenNo,
}: OutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        {sectionNo}
        {` ${String(
          translate(
            'cohabitationAgreement.stepFour.document.cirsumstancesChange.title'
          )
        )}`}
      </Text>
      <Text>
        {childrenNo === 0
          ? String(
              translate(
                'cohabitationAgreement.stepFour.document.cirsumstancesChange.noKids'
              )
            )
          : String(
              translate(
                'cohabitationAgreement.stepFour.document.cirsumstancesChange.withKids'
              )
            )}
      </Text>
    </View>
  );
}
