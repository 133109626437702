import { Stack } from '@mui/material';
import SoleOwnership from '#/components/pages/CohabitationAgreement/steps/Inventory/sole-ownership';
import CommonOwnership from '#/components/pages/CohabitationAgreement/steps/Inventory/common-ownership';

export default function Inventory() {
  return (
    <Stack spacing={6}>
      <SoleOwnership />
      <CommonOwnership />
    </Stack>
  );
}
