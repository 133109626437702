import { MenuItem, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { useFormContext } from 'react-hook-form';
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import RHFAutocompleteTwo from '#/components/shared/hook-form/RHFAutocomplete2';
import useFormattedNumericInput from '#/hooks/useFormattedNumericInput';

export function MeetYear() {
  const { translate } = useLocales();
  return (
    <Stack spacing={1}>
      {/* <Typography variant="subtitle1"> */}
      {/*  {String( */}
      {/*    translate('cohabitationAgreement.stepTwo.questions.1.question') */}
      {/*  )} */}
      {/* </Typography> */}
      <RHFTextField
        name="meet_year"
        label={String(translate('global.formLabels.meet_year'))}
      />
    </Stack>
  );
}

export function MovingInDate() {
  const { translate } = useLocales();
  return (
    <Stack spacing={1}>
      <RHFDatePicker
        name="date_of_moving_in"
        label={String(translate('global.formLabels.date_of_moving_in'))}
      />
    </Stack>
  );
}

export function JointHouseholdAccountBank() {
  const { translate } = useLocales();
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">
        {String(
          translate(
            'cohabitationAgreement.stepTwo.jointQuestions.joint_household_account_bank'
          )
        )}
      </Typography>
      <RHFTextField
        name="joint_household_account_bank"
        label={String(
          translate('global.formLabels.joint_household_account_bank')
        )}
      />
    </Stack>
  );
}

const cadenceOptions = [
  {
    value: 'monthly',
  },
  {
    value: 'bi_monthly',
  },
  {
    value: 'quarterly',
  },
];

type CadenceProps = {
  name: string;
  options?: { value: string }[];
};

export function Cadence({ name, options }: CadenceProps) {
  const { translate } = useLocales();

  const [initialOptions, setInitialOptions] = useState(cadenceOptions);

  useEffect(() => {
    if (options) {
      setInitialOptions(options);
    }
  }, [options]);

  return (
    <RHFSelect
      name={name}
      label={String(translate('global.formLabels.cadenceSelect'))}
    >
      {initialOptions.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          <Typography sx={{ textTransform: 'none' }}>
            {String(
              translate(
                `cohabitationAgreement.stepTwo.periodOptions.${option.value}`
              )
            )}
          </Typography>
        </MenuItem>
      ))}
    </RHFSelect>
  );
}

export function EqualPaymentAmount() {
  const { translate } = useLocales();
  const { setValue, watch } = useFormContext();
  const { formattedValue, handleValueChange } = useFormattedNumericInput({
    name: 'equal_payment_amount',
    setValue,
    watch,
  });
  return (
    <RHFTextField
      name="equal_payment_amount"
      label={String(translate('global.formLabels.amount'))}
      onChange={handleValueChange}
      value={formattedValue}
    />
  );
}

export function DateOfFirstPayment() {
  const { translate } = useLocales();
  return (
    <RHFDatePicker
      variant="outlined"
      name="date_of_first_payment"
      label={String(translate('global.formLabels.date_of_first_payment'))}
    />
  );
}

type CompensationPaymentsProps = {
  options: { value: string }[];
  name?: string;
  showSharePayment?: boolean;
};

export function CompensationPayments({
  options,
  name = 'compensation_payment_cadence',
  showSharePayment = false,
}: CompensationPaymentsProps) {
  const { translate } = useLocales();
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">
        {String(
          translate(
            'cohabitationAgreement.stepTwo.jointQuestions.compensation_payment_cadence'
          )
        )}
      </Typography>
      <Stack direction="row" spacing={2}>
        {showSharePayment && (
          <RHFTextField
            name="compensation_share"
            label={String(translate('global.formLabels.shareYouPay'))}
          />
        )}
        <RHFSelect
          name={name}
          label={String(translate('global.formLabels.cadenceSelect'))}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <Typography sx={{ textTransform: 'none' }}>
                {String(
                  translate(
                    `cohabitationAgreement.stepTwo.periodOptions.${option.value}`
                  )
                )}
              </Typography>
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>
    </Stack>
  );
}

const expensesOptions = [
  {
    value: 'rent',
  },
  {
    value: 'additional',
  },
  {
    value: 'radioTV',
  },
  {
    value: 'groceries',
  },
  {
    value: 'cleaning',
  },
];

export function ExpensesToBeCovered() {
  const { translate } = useLocales();

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">
        {String(
          translate(
            'cohabitationAgreement.stepTwo.jointQuestions.expenses_to_be_covered'
          )
        )}
      </Typography>
      <RHFAutocompleteTwo
        name="expenses_to_be_covered"
        label={String(translate('global.formLabels.expenses_to_be_covered'))}
        multiple
        freeSolo
        options={expensesOptions.map((option) => option.value)}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option}>
            {String(
              translate(
                `cohabitationAgreement.expensesOptions.${option}`,
                option
              )
            )}
          </MenuItem>
        )}
        renderTags={(selected, getTagProps) =>
          selected.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option}
              label={String(
                translate(
                  `cohabitationAgreement.expensesOptions.${option}`,
                  option
                )
              )}
              size="small"
              color="primary"
              variant="filled"
              sx={{
                textTransform: 'none',
              }}
            />
          ))
        }
      />
    </Stack>
  );
}
