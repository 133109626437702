import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useGetInventoryList } from '#/api/servicesQueries';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import COInventoryForm from '#/components/pages/CohabitationAgreement/steps/Inventory/common-ownership/inventory-form';
import COInventoryDetails from '#/components/pages/CohabitationAgreement/steps/Inventory/common-ownership/inventory-details';

export default function CommonOwnership() {
  const [addNewItem, setAddNewItem] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<number | boolean>(false);

  const { translate } = useLocales();
  const handleAddNewItem = () => setAddNewItem(true);
  const handleEditItem = (id: number) => setEditItem(id);

  const { data: inventoryList } = useGetInventoryList(true);

  const isInitial = inventoryList?.results?.length === 0;

  return (
    <Box>
      <Typography variant="subtitle1">
        {String(
          translate('cohabitationAgreement.stepThree.commonlyPurchasedList')
        )}
      </Typography>
      {isInitial || addNewItem || editItem ? (
        <COInventoryForm
          resetAddNew={() => setAddNewItem(false)}
          resetEdit={() => setEditItem(false)}
          isAddNew={addNewItem}
          isEdit={!!editItem}
          inventoryItemID={Number(editItem)}
        />
      ) : (
        <>
          {inventoryList?.results?.map((item) => (
            <COInventoryDetails
              key={item.id}
              {...item}
              editItem={handleEditItem}
            />
          ))}
          <Button
            onClick={handleAddNewItem}
            variant="contained"
            sx={{ marginTop: 1 }}
            startIcon={<Iconify icon="bi:plus" />}
          >
            <Typography>{String(translate('global.addNew'))}</Typography>
          </Button>
        </>
      )}
    </Box>
  );
}
