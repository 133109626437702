import PartiesOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/inventory/parties-output';
import SoleOwnershipOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/inventory/sole-ownership-output';
import JoinedOwnershipOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/inventory/joined-ownership-output';
import OwnershipBenefiter from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/inventory/ownership-benefiter';

type Props = {
  user: any;
  partner: any;
  adress: string;
  partnerAdress: string;
  userOwnershipInventory: any;
  partnerOwnershipInventory: any;
  joinedOwnershipInventory: any;
  userBenefitedInventory: any;
  partnerBenefitedInventory: any;
};

export default function InventoryDocument({
  user,
  partner,
  adress,
  partnerAdress,
  userOwnershipInventory,
  partnerOwnershipInventory,
  joinedOwnershipInventory,
  userBenefitedInventory,
  partnerBenefitedInventory,
}: Props) {
  return (
    <>
      <PartiesOutput
        user={user}
        adress={adress}
        partner={partner}
        partnerAdress={partnerAdress}
      />
      <SoleOwnershipOutput
        owner={`${user?.first_name} ${user?.last_name}`}
        inventoryList={userOwnershipInventory}
      />
      <SoleOwnershipOutput
        owner={`${partner?.first_name} ${partner?.last_name}`}
        inventoryList={partnerOwnershipInventory}
      />
      <JoinedOwnershipOutput inventoryList={joinedOwnershipInventory} />
      <OwnershipBenefiter
        owner={`${user?.first_name} ${user?.last_name}`}
        inventoryList={userBenefitedInventory}
      />
      <OwnershipBenefiter
        owner={`${partner?.first_name} ${partner?.last_name}`}
        inventoryList={partnerBenefitedInventory}
      />
    </>
  );
}
