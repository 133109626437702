import { ReactNode } from 'react';
import {
  PartnersInfo,
  PersonalInfo,
} from '#/components/pages/CohabitationAgreement/steps/ContractParties/parties-forms';
import ContractParties from '#/components/pages/CohabitationAgreement/steps/ContractParties';
import LifeSituation from '#/components/pages/CohabitationAgreement/steps/LifeSituation';
import {
  MeetYear,
  MovingInDate,
} from '#/components/pages/CohabitationAgreement/steps/LifeSituation/joint-questions';
import BothEmployedNoChildren from '#/components/pages/CohabitationAgreement/steps/LifeSituation/both-employed-no-children';
import OneFullTimeOnePartTime from '#/components/pages/CohabitationAgreement/steps/LifeSituation/one-full-time-one-part-time';
import OneFullTimeOtherRunsHouse from '#/components/pages/CohabitationAgreement/steps/LifeSituation/one-full-time-other-runs-house';
import Inventory from '#/components/pages/CohabitationAgreement/steps/Inventory';
import DocumentOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput';

export const SITUATION = {
  BOTH_EMPLOYED_NO_CHILDREN: 'both_employed_no_children',
  ONE_FULL_TIME_ONE_PART_TIME: 'one_full_time_one_part_time_no_children',
  ONE_FULL_TIME_ONE_HOUSEHOLD_WITH_CHILDREN:
    'one_full_time_one_household_with_children',
};

export const stepsContent = [
  {
    content: () => <ContractParties />,
  },
  {
    content: () => <LifeSituation />,
  },
  {
    content: () => <Inventory />,
  },
  {
    content: () => <DocumentOutput />,
  },
];

export const contractPartiesQuestions = [
  {
    id: 1,
    questionKey: 'partner',
    children: {
      content: () => <PartnersInfo />,
    },
  },
  {
    id: 2,
    questionKey: 'self',
    children: {
      content: () => <PersonalInfo />,
    },
  },
];

const situationComponentsMap: Record<string, ReactNode> = {
  [SITUATION.BOTH_EMPLOYED_NO_CHILDREN]: <BothEmployedNoChildren />,
  [SITUATION.ONE_FULL_TIME_ONE_PART_TIME]: <OneFullTimeOnePartTime />,
  [SITUATION.ONE_FULL_TIME_ONE_HOUSEHOLD_WITH_CHILDREN]: (
    <OneFullTimeOtherRunsHouse />
  ),
};

const situationQuestions = (situation: string) =>
  situationComponentsMap[situation] || null;

export const lifeSituationQuestions = [
  {
    id: 1,
    questionKey: 'meet_year',
    children: {
      content: () => <MeetYear />,
    },
  },
  {
    id: 2,
    questionKey: 'date_of_moving_in',
    children: {
      content: () => <MovingInDate />,
    },
  },
  {
    id: 3,
    questionKey: 'situation',
    answers: Object.values(SITUATION).map((value) => ({ value })),
    children: {
      content: (value: string) => situationQuestions(value),
    },
  },
  {
    id: 4,
    questionKey: 'current_rental_agreement_name',
    answers: [{ value: 'you' }, { value: 'partner' }, { value: 'both' }],
  },
  {
    id: 5,
    questionKey: 'change_contract_plans',
    answers: [
      { value: 'sublease_agreement' },
      { value: 'include_both' },
      { value: 'no' },
    ],
  },
];
