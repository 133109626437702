import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import ReactPlayer from 'react-player/youtube';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { StyledRadioGroup } from '#/components/pages/PensionProvision/styles';
import { contractPartiesQuestions } from '#/components/pages/CohabitationAgreement/stepsData';
import { ICohabitationAgreementValues } from '#/types/cohabitationAgreeement';
import useAuth from '#/hooks/useAuth';

export default function ContractParties() {
  const { watch, control, setValue } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const { translate } = useLocales();
  const { user } = useAuth();

  const handleDefaultValues = (question: string) =>
    watch?.(`${question}` as keyof ICohabitationAgreementValues);

  const handleOnChange = (
    questionName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(questionName, event.target.value);
  };

  const translatedQuestions = contractPartiesQuestions.map((question, i) => ({
    ...question,
    question: translate(
      `cohabitationAgreement.stepOne.questions.${i}.question`,
      {
        partnersGender: translate(
          `cohabitationAgreement.stepOne.${
            user?.gender === 'female' ? 'malePartner' : 'femalePartner'
          }`
        ),
      }
    ),
  }));

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactPlayer
          url="https://youtu.be/VtaVmYaoGf4"
          playing
          light="https://storage.gutgeregelt.ch/assets/main-landings/sections/youtubeThumbnail.webp"
          width={670}
        />
      </Box>
      <Stack spacing={5}>
        {translatedQuestions.map((question, index) => (
          <div
            key={question.questionKey}
            ref={(ref) => {
              refs.current[`${question.questionKey}`] = ref;
            }}
          >
            <Controller
              name={question.questionKey}
              control={control}
              defaultValue={handleDefaultValues(question.questionKey)}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StyledRadioGroup
                  name={question.questionKey}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleOnChange(question.questionKey, e);
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                  >
                    {String(question.question)}
                  </Typography>
                  {!!error && (
                    <FormHelperText error={!!error} sx={{ mx: 0 }}>
                      {error && error?.message}
                    </FormHelperText>
                  )}
                </StyledRadioGroup>
              )}
            />
            {question.children && <>{question.children.content()}</>}
          </div>
        ))}
      </Stack>
    </Box>
  );
}
