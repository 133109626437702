import { useLocation } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import Confetti from 'react-confetti';
import React from 'react';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { toast } from 'react-hot-toast';
import useAuth from '#/hooks/useAuth';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';
import useDisableDownloads from '#/hooks/useDisableDownloads';
import BlurryPurchaseCover from '#/components/shared/ui/BlurryPurchaseCover';
import Output from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/document';
import useLocales from '#/hooks/useLocales';
import {
  useGetContractParties,
  useGetInventoryList,
  useGetServiceForm,
  useSubmitDocument,
} from '#/api/servicesQueries';
import { useUpdateProfile } from '#/api/userQueries';
import { useGetChildren } from '#/api/childrenQueries';
import HighlightedTextFormat from '#/components/pages/MarriageContract/steps/DocumentOutput/highlight-formats';

export default function DocumentOutput() {
  const { user, refetch } = useAuth();
  const { state } = useLocation();
  const { translate } = useLocales();
  const { mutateAsync: submitDocument, isLoading: submitDoc } =
    useSubmitDocument('cohabitation-agreement/create');
  const { handleDisableDownloads } = useDisableDownloads(
    'cohabitation_agreement'
  );
  const { mutateAsync: updateProfile } = useUpdateProfile();
  const { data: contractParties } = useGetContractParties();
  const { data: outputValues } = useGetServiceForm('cohabitation-agreement');
  const { data: children } = useGetChildren();
  const { data: userOwnershipInventory } = useGetInventoryList(
    false,
    contractParties?.results[1]?.id
  );
  const { data: partnerOwnershipInventory } = useGetInventoryList(
    false,
    contractParties?.results[0]?.id
  );
  const { data: joinedOwnershipInventory } = useGetInventoryList(true);

  const { data: userBenefitedInventory } = useGetInventoryList(
    true,
    contractParties?.results[1]?.id
  );
  const { data: partnerBenefitedInventory } = useGetInventoryList(
    true,
    contractParties?.results[0]?.id
  );

  const childrenNo = children?.length;

  const generatePDFDocument = async () => {
    const blob = await pdf(
      <Output
        outputValues={{
          user: {
            ...user,
            ...contractParties?.results[1],
          },
          partner: contractParties?.results[0],
          meet_year: outputValues?.meet_year,
          date_of_moving_in: outputValues?.date_of_moving_in,
          situation: outputValues?.situation,
          who_works_full_time: outputValues?.who_works_full_time,
          workload_distribution: outputValues?.workload_distribution,
          childrenNo,
          compensation_payment_cadence:
            outputValues?.compensation_payment_cadence,
          equal_payment_amount: outputValues?.equal_payment_amount,
          equal_payment_frequency: outputValues?.equal_payment_frequency,
          date_of_first_payment: outputValues?.date_of_first_payment,
          expenses_to_be_covered: outputValues?.expenses_to_be_covered,
          payment_ratio_per_party: outputValues?.payment_ratio_per_party,
          monthly_transfer_for_free_disposal:
            outputValues?.monthly_transfer_for_free_disposal,
          free_disposal_amount: outputValues?.free_disposal_amount,
          change_contract_plans: outputValues?.change_contract_plans,
          current_rental_agreement_name:
            outputValues?.current_rental_agreement_name,
          userOwnershipInventory,
          partnerOwnershipInventory,
          joinedOwnershipInventory,
          userBenefitedInventory,
          partnerBenefitedInventory,
          compensation_share: outputValues?.compensation_share,
          account_balance_in_case_of_separation:
            outputValues?.account_balance_in_case_of_separation,
        }}
      />
    ).toBlob();

    const formData = new FormData();
    formData.append(
      'document',
      blob,
      `${String(
        translate('global.services.cohabitationAgreement')
      )}_${contractParties?.results[1]?.first_name}_${contractParties
        ?.results[1]?.last_name}_${new Date()
        .toLocaleDateString('en-GB')
        .split('/')
        .join('-')}.pdf`
    );
    formData.append('step', '4');

    await submitDocument(formData)
      .then((res) => {
        const targetURL = res?.data?.document;
        window.open(targetURL, '_blank');
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.document_submit'))
        );
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updateProfile({
      // eslint-disable-next-line no-unsafe-optional-chaining
      downloaded: ['cohabitation_agreement'],
    }).then(() => refetch());
  };

  const hideConfetti =
    user?.downloaded?.includes('cohabitation') || state?.markAsDone;

  const translatedNextSteps = Array.from({ length: 3 }, (_, i) =>
    String(translate(`cohabitationAgreement.stepFour.nextStep${i + 1}`))
  );

  return (
    <Box>
      {!hideConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={400}
          style={{ width: '100%' }}
        />
      )}
      <Stack>
        <Typography gutterBottom>
          {String(translate('cohabitationAgreement.stepFour.nextStepsTitle'))}
        </Typography>
        {translatedNextSteps.map((step, i) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            key={`key-steps${i}`}
            sx={{ marginY: 1 }}
          >
            <Typography gutterBottom>{i + 1}.</Typography>
            <Typography gutterBottom>{step}</Typography>
          </Stack>
        ))}
        <UploadSignedDoc
          serviceName="cohabitation-agreement/create"
          documentType="cohabitationAgreement"
          serviceInvalidateKey="cohabitation-agreement"
        />
      </Stack>
      {!state?.markAsDone && (
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '50%',
            },
            paddingTop: '20px',
            margin: '0 auto',
          }}
        >
          {handleDisableDownloads() ? (
            <BlurryPurchaseCover
              service="cohabitationAgreement.stepFour.document"
              user={user}
            />
          ) : (
            <PDFViewer
              style={{
                width: '100%',
                height: '550px',
              }}
              showToolbar={false}
            >
              <Output
                outputValues={{
                  user: {
                    ...user,
                    ...contractParties?.results[1],
                  },
                  partner: contractParties?.results[0],
                  meet_year: outputValues?.meet_year,
                  date_of_moving_in: outputValues?.date_of_moving_in,
                  situation: outputValues?.situation,
                  who_works_full_time: outputValues?.who_works_full_time,
                  workload_distribution: outputValues?.workload_distribution,
                  childrenNo,
                  compensation_payment_cadence:
                    outputValues?.compensation_payment_cadence,
                  equal_payment_amount: outputValues?.equal_payment_amount,
                  equal_payment_frequency:
                    outputValues?.equal_payment_frequency,
                  date_of_first_payment: outputValues?.date_of_first_payment,
                  expenses_to_be_covered: outputValues?.expenses_to_be_covered,
                  payment_ratio_per_party:
                    outputValues?.payment_ratio_per_party,
                  monthly_transfer_for_free_disposal:
                    outputValues?.monthly_transfer_for_free_disposal,
                  free_disposal_amount: outputValues?.free_disposal_amount,
                  change_contract_plans: outputValues?.change_contract_plans,
                  current_rental_agreement_name:
                    outputValues?.current_rental_agreement_name,
                  userOwnershipInventory,
                  partnerOwnershipInventory,
                  joinedOwnershipInventory,
                  userBenefitedInventory,
                  partnerBenefitedInventory,
                  compensation_share: outputValues?.compensation_share,
                  account_balance_in_case_of_separation:
                    outputValues?.account_balance_in_case_of_separation,
                }}
              />
            </PDFViewer>
          )}
          <Button
            disabled={handleDisableDownloads() || submitDoc}
            variant="contained"
            color="primary"
            onClick={generatePDFDocument}
            sx={{ width: 1, mt: 2 }}
          >
            <Typography>{String(translate('global.download'))}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}
