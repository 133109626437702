import { Document, Page } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import PartiesOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/parties-output';
import PreliminaryRemarkOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/preliminary-remark-output';
import OwnershipOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/ownership-output';
import CostOfLivingOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/cost-of-living-output';
import ChildrenSectionOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/children-section-output';
import LivingOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/living-output';
import CirsumstancesChange from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/cirsumstances-change';
import DissolutionOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/dissolution-output';
import SignatureOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/signature-output';
import InventoryDocument from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/inventory';
import IntroOutput from '#/components/pages/CohabitationAgreement/steps/DocumentOutput/intro-output';

type OutputProps = {
  outputValues?: any;
};

export default function Output({ outputValues }: OutputProps) {
  const { translate } = useLocales();

  const {
    user,
    partner,
    meet_year,
    date_of_moving_in,
    situation,
    who_works_full_time,
    workload_distribution,
    childrenNo,
    compensation_payment_cadence,
    equal_payment_amount,
    equal_payment_frequency,
    date_of_first_payment,
    expenses_to_be_covered,
    monthly_transfer_for_free_disposal,
    free_disposal_amount,
    current_rental_agreement_name,
    change_contract_plans,
    userOwnershipInventory,
    partnerOwnershipInventory,
    joinedOwnershipInventory,
    userBenefitedInventory,
    partnerBenefitedInventory,
    compensation_share,
    account_balance_in_case_of_separation,
  } = outputValues;

  const adress = `${user?.address?.street} ${user?.address
    ?.house_number}, ${user?.address?.zip_code} ${user?.address
    ?.city}, ${String(
    translate(`global.${user?.address?.country.toLowerCase()}`)
  )}`;

  const partnerAdress = `${partner?.address?.street} ${partner?.address
    ?.house_number}, ${partner?.address?.zip_code} ${partner?.address
    ?.city}, ${String(
    translate(`global.${partner?.address?.country.toLowerCase()}`)
  )}`;

  const fullTimeWorker =
    who_works_full_time === 'you'
      ? `${user?.first_name} ${user?.last_name}`
      : `${partner?.first_name} ${partner?.last_name}`;

  const partTimeWorker =
    who_works_full_time === 'you'
      ? `${partner?.first_name} ${partner?.last_name}`
      : `${user?.first_name} ${user?.last_name}`;

  const percentagePaidByPartner = 100 - outputValues.payment_ratio_per_party;
  const percentagePaidByMe = outputValues.payment_ratio_per_party;

  const compensationPaidByMe = compensation_share;
  const compensationPaidByPartner = 100 - compensation_share;

  const paidByMeInCaseOfSeparation = account_balance_in_case_of_separation;
  const paidByPartnerInCaseOfSeparation =
    100 - account_balance_in_case_of_separation;

  const amountPaidByMe = (equal_payment_amount * percentagePaidByMe) / 100;
  const amountPaidByPartner =
    (equal_payment_amount * percentagePaidByPartner) / 100;

  const agreementName =
    current_rental_agreement_name === 'you'
      ? `${user?.first_name} ${user?.last_name}`
      : `${partner?.first_name} ${partner?.last_name}`;

  const hasChildren = childrenNo > 0;

  return (
    <Document
      pageMode="fullScreen"
      title={String(translate('global.services.cohabitationAgreement'))}
    >
      <Page size="A4" style={styles.page}>
        <IntroOutput user={user} address={adress} />
        <PartiesOutput
          user={user}
          partner={partner}
          adress={adress}
          partnerAdress={partnerAdress}
        />
        <PreliminaryRemarkOutput
          meet_year={meet_year}
          date_of_moving_in={date_of_moving_in}
          situation={situation}
          workload_distribution={workload_distribution}
          fullTimeWorker={fullTimeWorker}
          partTimeWorker={partTimeWorker}
          childrenNo={childrenNo}
          adress={adress}
        />
        <OwnershipOutput />
        <CostOfLivingOutput
          firstParty={`${user?.first_name} ${user?.last_name}`}
          secondParty={`${partner?.first_name} ${partner?.last_name}`}
          fullTimeWorker={fullTimeWorker}
          situation={situation}
          compensation_payment_cadence={compensation_payment_cadence}
          equal_payment_amount={equal_payment_amount}
          date_of_first_payment={date_of_first_payment}
          equal_payment_frequency={equal_payment_frequency}
          expenses_to_be_covered={expenses_to_be_covered}
          partTimeWorker={partTimeWorker}
          percentagePaidByMe={percentagePaidByMe}
          percentagePaidByPartner={percentagePaidByPartner}
          amountPaidByMe={amountPaidByMe}
          amountPaidByPartner={amountPaidByPartner}
          monthly_transfer_for_free_disposal={
            monthly_transfer_for_free_disposal
          }
          free_disposal_amount={free_disposal_amount}
          userGender={user?.gender}
          compensationPaidByMe={compensationPaidByMe}
          compensationPaidByPartner={compensationPaidByPartner}
          paidByMeInCaseOfSeparation={paidByMeInCaseOfSeparation}
          paidByPartnerInCaseOfSeparation={paidByPartnerInCaseOfSeparation}
        />
        {hasChildren && <ChildrenSectionOutput childrenNo={childrenNo} />}
        <LivingOutput
          sectionNo={childrenNo > 0 ? 5 : 4}
          isBothParties={current_rental_agreement_name === 'both'}
          agreementName={agreementName}
          contractPlans={change_contract_plans}
          nonAgreementName={
            current_rental_agreement_name === 'you'
              ? `${partner?.first_name} ${partner?.last_name}`
              : `${user?.first_name} ${user?.last_name}`
          }
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <CirsumstancesChange
          sectionNo={childrenNo > 0 ? 6 : 5}
          childrenNo={childrenNo}
        />
        <DissolutionOutput
          sectionNo={childrenNo > 0 ? 7 : 6}
          city={user?.address.city}
        />
        <SignatureOutput
          address={adress}
          partnerAdress={partnerAdress}
          user={`${user?.first_name} ${user?.last_name}`}
          partner={`${partner?.first_name} ${partner?.last_name}`}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <InventoryDocument
          user={user}
          partner={partner}
          adress={adress}
          partnerAdress={partnerAdress}
          userOwnershipInventory={userOwnershipInventory}
          partnerOwnershipInventory={partnerOwnershipInventory}
          joinedOwnershipInventory={joinedOwnershipInventory}
          userBenefitedInventory={userBenefitedInventory}
          partnerBenefitedInventory={partnerBenefitedInventory}
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <SignatureOutput
          address={adress}
          partnerAdress={partnerAdress}
          user={`${user?.first_name} ${user?.last_name}`}
          partner={`${partner?.first_name} ${partner?.last_name}`}
        />
      </Page>
    </Document>
  );
}
