import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  Cadence,
  CompensationPayments,
  DateOfFirstPayment,
  EqualPaymentAmount,
  ExpensesToBeCovered,
  JointHouseholdAccountBank,
} from '#/components/pages/CohabitationAgreement/steps/LifeSituation/joint-questions';
import useLocales from '#/hooks/useLocales';

const compensationOptions = [
  {
    value: 'weekly',
  },
  {
    value: 'bi_weekly',
  },
  {
    value: 'monthly',
  },
  {
    value: 'bi_monthly',
  },
  {
    value: 'quarterly',
  },
];

export default function BothEmployedNoChildren() {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginTop: 3,
      }}
    >
      <JointHouseholdAccountBank />

      <Stack spacing={1}>
        <Typography variant="subtitle1">
          {String(
            translate(
              'cohabitationAgreement.stepTwo.jointQuestions.equal_payment'
            )
          )}
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <EqualPaymentAmount />
            <Cadence name="equal_payment_frequency" />
          </Stack>
          <DateOfFirstPayment />
        </Stack>
      </Stack>

      <CompensationPayments options={compensationOptions} />
      <ExpensesToBeCovered />
    </Box>
  );
}
