import * as Yup from 'yup';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import {
  invalidateServicesStatsQueries,
  useAddInventoryItem,
  useGetContractParties,
  useGetInventoryItem,
  useUpdateInventoryItem,
} from '#/api/servicesQueries';
import { resetSwissSeparator } from '#/utils/formatNumber';
import useFormattedNumericInput from '#/hooks/useFormattedNumericInput';

type Props = {
  resetAddNew?: () => void;
  resetEdit?: () => void;
  isAddNew?: boolean;
  isEdit?: boolean;
  inventoryItemID?: number;
};

export default function COInventoryForm({
  resetAddNew,
  resetEdit,
  isAddNew,
  isEdit,
  inventoryItemID,
}: Props) {
  const { translate } = useLocales();
  const { mutateAsync: addInventoryItem } = useAddInventoryItem();
  const { mutateAsync: updateInventoryItem } =
    useUpdateInventoryItem(inventoryItemID);
  const { data: contractParties } = useGetContractParties();
  const { data: inventoryItem } = useGetInventoryItem(
    inventoryItemID,
    !!isEdit
  );

  const Schema = Yup.object().shape({
    description_good: Yup.string().required('validations.description_good'),
    previous_owner: Yup.string().required('validations.next_benefiter'),
    approximate_value: Yup.number()
      .typeError('validations.must_be_number')
      .required('validations.shouldNotBeEmpty'),
  });

  const defaultValues = useMemo(
    () => ({
      description_good: inventoryItem?.description_good || '',
      previous_owner: inventoryItem?.previous_owner?.id || '',
      approximate_value: inventoryItem?.approximate_value || '',
      is_joint_purchase: inventoryItem?.is_joint_purchase || true,
    }),
    [inventoryItem]
  );

  const methods = useForm({
    resolver: yupResolver(Schema) as any,
    defaultValues,
  });
  const { handleSubmit, reset, setValue, watch } = methods;
  const { formattedValue, handleValueChange } = useFormattedNumericInput({
    name: 'approximate_value',
    setValue,
    watch,
  });

  const onSubmit = async (data: any) => {
    try {
      if (isEdit) {
        await updateInventoryItem(data);
        toast.success(String(translate('global.success')));
        invalidateServicesStatsQueries.getInventoryList();
        resetEdit?.();
      } else {
        await addInventoryItem(data);
        toast.success(String(translate('global.success')));
        invalidateServicesStatsQueries.getInventoryList();
        resetAddNew?.();
      }
    } catch (e) {
      toast.error(String(translate('global.error')));
    }
  };

  useEffect(() => {
    if (isEdit && inventoryItem) {
      reset(defaultValues);
    }
  }, [isEdit, inventoryItem]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginY: 2 }}
      >
        <Stack direction="row" spacing={2}>
          <RHFTextField
            name="description_good"
            label={String(translate('global.formLabels.good_description'))}
          />
          <RHFTextField
            name="approximate_value"
            label={String(translate('global.formLabels.approximate_value'))}
            onChange={handleValueChange}
            value={formattedValue}
          />
        </Stack>
        <RHFSelect
          variant="outlined"
          name="previous_owner"
          label={String(
            translate('global.formLabels.benefiterAfterSeparation')
          )}
        >
          {contractParties?.results?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {`${option.first_name} ${option.last_name}`}
            </MenuItem>
          ))}
        </RHFSelect>
        <Stack direction="row" justifyContent="flex-start" spacing={2}>
          <LoadingButton
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            type="submit"
            size="small"
            variant="contained"
            startIcon={<Iconify icon="mdi:content-save" />}
          >
            <Typography variant="subtitle1" fontWeight="light">
              {/* {isAddNew */}
              {/*  ? String(translate('global.add')) */}
              {/*  : String(translate('global.save'))} */}
              {String(translate('global.save'))}
            </Typography>
          </LoadingButton>
          {(isAddNew || isEdit) && (
            <Button
              variant="text"
              onClick={() => {
                if (isAddNew) {
                  resetAddNew?.();
                }
                if (isEdit) {
                  resetEdit?.();
                }
              }}
              size="small"
            >
              <Typography variant="subtitle1" fontWeight="light">
                {String(translate('global.cancel'))}
              </Typography>
            </Button>
          )}
        </Stack>
      </Box>
    </FormProvider>
  );
}
