import { FC } from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
};

const ConfirmDelete: FC<Props> = ({ open, onClose, onConfirm, isLoading }) => {
  const { translate } = useLocales();

  return (
    <DialogAnimate open={open} onClose={onClose}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle2" gutterBottom>
          {String(
            translate(
              'cohabitationAgreement.stepThree.confirmDeleteInventory.title'
            )
          )}
        </Typography>
        <Typography variant="caption">
          {String(
            translate(
              'cohabitationAgreement.stepThree.confirmDeleteInventory.description'
            )
          )}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography>
              {String(
                translate(
                  'cohabitationAgreement.stepThree.confirmDeleteInventory.cancel'
                )
              )}
            </Typography>
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={onConfirm}
            size="small"
            sx={{ width: 1 }}
            loading={isLoading}
          >
            <Typography>
              {String(
                translate(
                  'cohabitationAgreement.stepThree.confirmDeleteInventory.delete'
                )
              )}
            </Typography>
          </LoadingButton>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
};

export default ConfirmDelete;
