import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';
import {
  Cadence,
  CompensationPayments,
  DateOfFirstPayment,
  EqualPaymentAmount,
  ExpensesToBeCovered,
  JointHouseholdAccountBank,
} from '#/components/pages/CohabitationAgreement/steps/LifeSituation/joint-questions';
import { RHFTextField } from '#/components/shared/hook-form';
import useAuth from '#/hooks/useAuth';
import useFormattedNumericInput from '#/hooks/useFormattedNumericInput';

const compensationOptions = [
  {
    value: 'weekly',
  },
  {
    value: 'bi_weekly',
  },
  {
    value: 'monthly',
  },
  {
    value: 'bi_monthly',
  },
  {
    value: 'quarterly',
  },
];

export default function OneFullTimeOtherRunsHouse() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { setValue, watch } = useFormContext();
  const { formattedValue, handleValueChange } = useFormattedNumericInput({
    name: 'free_disposal_amount',
    setValue,
    watch,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginTop: 3,
      }}
    >
      <RHFStyledRadioGroup
        name="household_and_children_responsibility"
        question={{
          questionKey: 'household_and_children_responsibility',
          question: String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOtherNotWorking.household_and_children_responsibility'
            )
          ),
          answers: [
            {
              value: 'your_partner',
              label: String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.your_partner',
                  {
                    partnersGender: translate(
                      `cohabitationAgreement.stepOne.${
                        user?.gender === 'M' ? 'femalePartner' : 'malePartner'
                      }`
                    ),
                  }
                )
              ),
            },
            {
              value: 'you',
              label: String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.you'
                )
              ),
            },
          ],
        }}
      />
      <JointHouseholdAccountBank />

      <Stack spacing={1}>
        <Typography variant="subtitle1">
          {String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOnePartTime.equal_payment'
            )
          )}
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <EqualPaymentAmount />
            <Cadence name="equal_payment_frequency" />
          </Stack>
          <DateOfFirstPayment />
        </Stack>
      </Stack>

      <CompensationPayments showSharePayment options={compensationOptions} />
      <ExpensesToBeCovered />
      <RHFStyledRadioGroup
        name="monthly_transfer_for_free_disposal"
        question={{
          questionKey: 'monthly_transfer_for_free_disposal',
          question: String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOtherNotWorking.monthly_transfer_for_free_disposal'
            )
          ),
          answers: [
            {
              value: 'yes',
              label: String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOtherNotWorking.yes'
                )
              ),
            },
            {
              value: 'no',
              label: String(
                translate(
                  'cohabitationAgreement.stepTwo.oneFullTimeOtherNotWorking.no'
                )
              ),
            },
          ],
        }}
      />
      <Stack spacing={1}>
        <Typography variant="subtitle1">
          {String(
            translate(
              'cohabitationAgreement.stepTwo.oneFullTimeOtherNotWorking.free_disposal_amount'
            )
          )}
        </Typography>
        <RHFTextField
          name="free_disposal_amount"
          label={String(translate('global.formLabels.amount'))}
          onChange={handleValueChange}
          value={formattedValue}
        />
      </Stack>
    </Box>
  );
}
