import { View, Text } from '@react-pdf/renderer';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';

type OutputProps = {
  sectionNo: number;
  agreementName: string;
  nonAgreementName: string;
  contractPlans: string;
  isBothParties: boolean;
};

export default function LivingOutput({
  sectionNo,
  agreementName,
  nonAgreementName,
  contractPlans,
  isBothParties,
}: OutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        {sectionNo}
        {` ${String(
          translate('cohabitationAgreement.stepFour.document.living.title')
        )}`}
      </Text>
      {!isBothParties && contractPlans === 'include_both' && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.living.onePartyNameAgreement',
              {
                agreement_name: agreementName,
              }
            )
          )}
        </Text>
      )}
      {!isBothParties && contractPlans === 'sublease_agreement' && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.living.onePartyNameAgreementSublease',
              {
                agreement_name: agreementName,
                non_agreement_name: nonAgreementName,
              }
            )
          )}
        </Text>
      )}
      {!isBothParties && contractPlans === 'no' && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.living.onePartyNameAgreementNoPlans',
              {
                agreement_name: agreementName,
              }
            )
          )}
        </Text>
      )}
      {isBothParties && contractPlans === null && (
        <Text>
          {String(
            translate(
              'cohabitationAgreement.stepFour.document.living.bothPartiesNameAgreement'
            )
          )}
        </Text>
      )}
      <Text style={{ marginTop: 5 }}>
        {String(
          translate('cohabitationAgreement.stepFour.document.living.rentBudget')
        )}
      </Text>
      <Text style={{ marginTop: 5 }}>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.living.equalRights'
          )
        )}
      </Text>
    </View>
  );
}
