import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import {
  invalidateServicesStatsQueries,
  useDeleteInventoryItem,
} from '#/api/servicesQueries';
import ConfirmDelete from '#/components/pages/CohabitationAgreement/steps/Inventory/confirm-delete';
import { formatWithThousandSeparator } from '#/utils/formatNumber';

type Props = {
  id: number;
  description_good: string;
  approximate_value: number;
  previous_owner: {
    id: number;
    first_name: string;
    last_name: string;
  };
  editItem: (id: number) => void;
};

export default function COInventoryDetails({
  id,
  description_good,
  approximate_value,
  previous_owner,
  editItem,
}: Props) {
  const [deleteModal, setDeleteModal] = useState(false);

  const { mutateAsync: deleteInventory, isLoading } =
    useDeleteInventoryItem(id);

  const handleModalOpen = () => setDeleteModal(true);
  const handleModalClose = () => setDeleteModal(false);

  const onDelete = async () => {
    await deleteInventory(id).then(() => {
      invalidateServicesStatsQueries.getInventoryList();
      handleModalClose();
    });
  };

  const { translate } = useLocales();

  return (
    <Box sx={{ marginY: 2 }}>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle1" noWrap sx={{ width: '200px' }}>
              {String(translate('global.description'))}:
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" noWrap>
                {`${description_good}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle1" noWrap sx={{ width: '200px' }}>
              {String(translate('global.formLabels.approximate_value'))}:
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" noWrap>
                {`CHF ${formatWithThousandSeparator(
                  String(approximate_value)
                )}`}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle1" noWrap sx={{ width: '200px' }}>
              {String(translate('global.owner'))}:
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" noWrap>
                {`${previous_owner?.first_name} ${previous_owner?.last_name}`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            size="small"
            variant="outlined"
            color="primary"
            startIcon={
              <Iconify icon="octicon:pencil-16" sx={{ fontSize: '1.5rem' }} />
            }
            onClick={() => editItem(id)}
          >
            <Typography variant="subtitle2" fontWeight="light">
              {String(translate('global.edit'))}
            </Typography>
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            startIcon={
              <Iconify icon="octicon:trash-16" sx={{ fontSize: '1.5rem' }} />
            }
            onClick={handleModalOpen}
          >
            <Typography variant="subtitle2" fontWeight="light">
              {String(translate('global.delete'))}
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <ConfirmDelete
        open={deleteModal}
        onClose={handleModalClose}
        onConfirm={onDelete}
        isLoading={isLoading}
      />
    </Box>
  );
}
