import Page from '#/components/shared/ui/Page';
import CohabitationAgreementForm from '#/components/pages/CohabitationAgreement';

export default function CohabitationAgreement() {
  return (
    <Page title="Konkubinatsvertrag">
      <CohabitationAgreementForm />
    </Page>
  );
}
