import { View, Text } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';

type OutputProps = {
  childrenNo: number;
};

export default function ChildrenSectionOutput({ childrenNo }: OutputProps) {
  const { translate } = useLocales();
  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        4{' '}
        {String(
          translate('cohabitationAgreement.stepFour.document.children.title', {
            childrenNo: String(
              translate(
                `cohabitationAgreement.stepFour.document.children.${
                  childrenNo === 1 ? 'singular' : 'plural'
                }`
              )
            ),
          })
        )}
      </Text>
      <Text>
        {String(
          translate('cohabitationAgreement.stepFour.document.children.text', {
            childrenNo: String(
              translate(
                `cohabitationAgreement.stepFour.document.children.${
                  childrenNo === 1 ? 'singularText' : 'pluralText'
                }`
              )
            ),
          })
        )}
      </Text>
    </View>
  );
}
