import { Text, View } from '@react-pdf/renderer';
import { ContractParty } from '#/types/marriageContract';
import useLocales from '#/hooks/useLocales';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import { fDate } from '#/utils/formatTime';

interface PersonalInfo extends ContractParty {
  gender: string;
  date_of_birth: string;
  civil_status: string;
}

type PartiesOutputProps = {
  user: PersonalInfo;
  partner: ContractParty;
  adress: string;
  partnerAdress: string;
};

export default function PartiesOutput({
  user,
  partner,
  adress,
  partnerAdress,
}: PartiesOutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section}>
      <Text>
        {String(
          translate('cohabitationAgreement.stepFour.document.inventory.title')
        )}
      </Text>
      <Text>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.inventory.agreementBetween'
          )
        )}
      </Text>
      <Text>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.spousePresentation',
            {
              partnerSurname: partner?.first_name,
              partnerName: partner?.last_name,
              partnerBirthday: fDate(partner?.birthday, 'dd. MMM yyyy', 'de'),
              partnerCitizenship: partner?.place_of_citizenship,
              partnerCivilStatus: String(
                translate(
                  `cohabitationAgreement.stepFour.document.civilStatus.${user?.civil_status}`
                )
              ).toLowerCase(),
              partnerAdress,
            }
          )
        )}
      </Text>
      <Text>
        {String(translate('cohabitationAgreement.stepFour.document.and'))}
      </Text>
      <Text>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.personalPresentation',
            {
              firstName: user?.first_name,
              lastName: user?.last_name,
              birthday: fDate(user?.date_of_birth, 'dd. MMM yyyy', 'de'),
              citizenship: user?.place_of_citizenship,
              civilStatus: String(
                translate(
                  `marriageContract.stepFour.document.civilStatus.${user?.civil_status}`
                )
              ).toLowerCase(),
              adress,
            }
          )
        )}
      </Text>
    </View>
  );
}
