import {
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-hot-toast';
import { FormProvider } from '#/components/shared/hook-form';
import { QontoConnector } from '#/components/pages/LivingWill/Steps/styles';
import useStepper from '#/hooks/useStepper';
import { stepsContent } from '#/components/pages/CohabitationAgreement/stepsData';
import useLocales from '#/hooks/useLocales';
import QontoStepIcon from '#/components/pages/LivingWill/Steps/QontoStepIcon';
import IntroHead from '#/components/shared/IntroHead';
import EnoughForNowModal from '#/components/shared/ui/EnoughForNowModal';
import {
  allValuesReset,
  schemaDefaults,
  stepOneValidation,
  stepTwoValidation,
} from '#/components/pages/CohabitationAgreement/schema';
import useAuth from '#/hooks/useAuth';
import {
  invalidateServicesStatsQueries,
  useCreateCohabitationAgreement,
  useGetServiceForm,
} from '#/api/servicesQueries';
import useServiceStats from '#/hooks/useServiceStats';
import MarkAsDone from '#/components/shared/mark-as-done';
import { fToDB } from '#/utils/formatTime';

export default function CohabitationAgreement() {
  const [openEnoughNow, setOpenEnoughNow] = useState<boolean>(false);

  const { state } = useLocation();
  const { user } = useAuth();
  const { translate } = useLocales();
  const { cohabitation_agreement } = useServiceStats();
  const { activeStep, setActiveStep, handleBack, handleNext } = useStepper(
    false,
    'cohabitation_agreement',
    stepsContent
  );

  const { data: cohabitationAgreement } = useGetServiceForm(
    'cohabitation-agreement'
  );
  const { mutateAsync: createCohabitationAgreement } =
    useCreateCohabitationAgreement();

  const navigate = useNavigate();
  const defaultValues = useMemo(
    () => schemaDefaults(user, activeStep, cohabitationAgreement),
    [user, activeStep, cohabitationAgreement]
  );

  const validationSteps = [stepOneValidation, stepTwoValidation];

  const methods = useForm({
    resolver: validationSteps[activeStep]
      ? yupResolver(validationSteps[activeStep] as any)
      : undefined,
    defaultValues,
    reValidateMode: 'onBlur',
  });

  const { handleSubmit, reset, watch, setValue } = methods;

  const handleOpenEnoughNow = () => setOpenEnoughNow(true);
  const handleCloseEnoughNow = () => setOpenEnoughNow(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onSubmit = async (data: any, startOver?: boolean) => {
    try {
      const setEmptyToNull = (obj: any): any => {
        if (obj && typeof obj === 'object') {
          if (Array.isArray(obj)) {
            return obj.map(setEmptyToNull);
          }
          return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => {
              if (value === '' || value === undefined || value === null) {
                return [key, null];
              }
              return [key, setEmptyToNull(value)];
            })
          );
        }
        return obj;
      };

      const transformedData = setEmptyToNull(data);

      let body: any;

      if (startOver) {
        body = {
          ...transformedData,
          ...allValuesReset,
        };
      } else {
        body = {
          ...transformedData,
          date_of_moving_in: fToDB(data.date_of_moving_in),
          date_of_first_payment: fToDB(data.date_of_first_payment),
          self: {
            ...data.self,
            first_name: data.self.first_name.trim(),
            last_name: data.self.last_name.trim(),
            birthday: fToDB(data.self.birthday),
          },
          partner: {
            ...data.partner,
            first_name: data.partner.first_name.trim(),
            last_name: data.partner.last_name.trim(),
            birthday: fToDB(data.partner.birthday),
          },
        };
      }

      await createCohabitationAgreement(body).then(() => {
        toast.success(String(translate('toast_notifications.success.saving')));
        invalidateServicesStatsQueries.getCohabitationAgreement();
        invalidateServicesStatsQueries.getServicesStats();

        handleNext();
        scrollToTop();
      });
    } catch (e) {
      toast.error(String(translate('toast_notifications.error.saving')));
    }
  };

  const translatedStepsData = stepsContent?.map((step, i) => ({
    ...step,
    label: translate(`cohabitationAgreement.stepperInfo.${i}.label`),
    title: translate(`cohabitationAgreement.stepperInfo.${i}.title`),
  }));

  useEffect(() => {
    if (cohabitationAgreement || activeStep) {
      reset(defaultValues);
    }
  }, [cohabitationAgreement, activeStep]);

  useEffect(
    () => () => {
      const onUnmountSave = async () => {
        await onSubmit(watch());
      };

      onUnmountSave().then((r) => r);
    },
    []
  );

  useEffect(() => {
    if (watch('current_rental_agreement_name') === 'both') {
      setValue('change_contract_plans', null);
    }
  }, [watch('current_rental_agreement_name')]);

  useEffect(() => {
    if (state?.startOver) {
      setActiveStep(0);
      onSubmit(watch(), true);
    }
  }, [state?.startOver]);

  if (
    state?.step === 'markAsDone' ||
    state?.step === 'history' ||
    state?.step === 'uploadDocument'
  ) {
    return (
      <MarkAsDone
        serviceName="cohabitation-agreement/create"
        documentType="cohabitationAgreement"
        serviceInvalidateKey="cohabitation-agreement"
      />
    );
  }

  return (
    <Container maxWidth="lg">
      {activeStep !== translatedStepsData.length - 1 ? (
        <IntroHead
          title={String(
            translate('cohabitationAgreement.mainHeader.regulateTogether')
          )}
          description=""
        />
      ) : (
        <IntroHead
          title={String(
            translate('cohabitationAgreement.mainHeader.effectiveDocs')
          )}
          description=""
        />
      )}
      <Stack sx={{ width: '100%', marginTop: 3 }} spacing={4}>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit((data) => onSubmit(data, false))}
        >
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            {translatedStepsData?.map((step, i) => (
              <Step
                key={`step-${step.title}`}
                onClick={() => {
                  if (i < activeStep) {
                    setActiveStep(i);
                  }
                  if (cohabitation_agreement?.service?.completed) {
                    setActiveStep(i);
                  }
                }}
                sx={{
                  ...((i < activeStep ||
                    cohabitation_agreement?.service?.completed) && {
                    cursor: 'pointer',
                  }),
                }}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {String(step.label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ p: 3, mt: 4 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1" mb={2}>
                {String(translatedStepsData[activeStep]?.title)}
              </Typography>
              {activeStep !== translatedStepsData.length - 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ mb: 2 }}
                  onClick={handleOpenEnoughNow}
                >
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ cursor: 'pointer' }}
                  >
                    {String(translate('global.enoughForNow'))}
                  </Typography>
                </Button>
              )}
            </Stack>
            <Divider
              sx={{
                mb: 3,
              }}
            />
            {translatedStepsData[activeStep]?.content()}
            <Divider
              sx={{
                mt: 4,
              }}
            />
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <Typography>{String(translate('global.back'))}</Typography>
              </Button>
              {activeStep === translatedStepsData.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/dashboard/home');
                  }}
                  color="success"
                  sx={{
                    color: '#fff',
                  }}
                >
                  <Typography>{String(translate('global.done'))}</Typography>
                </Button>
              )}
              {activeStep !== translatedStepsData.length - 1 && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <Typography>{String(translate('global.next'))}</Typography>
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Stack>
      <EnoughForNowModal
        openEnoughNow={openEnoughNow}
        handleCloseEnoughNow={handleCloseEnoughNow}
      />
    </Container>
  );
}
