import { Text, View } from '@react-pdf/renderer';
import styles from '#/components/pages/LivingWill/Steps/content/YourLivingWill/styles';
import useLocales from '#/hooks/useLocales';
import { fDate } from '#/utils/formatTime';

type PreliminaryRemarkOutputProps = {
  meet_year: string | number;
  date_of_moving_in: string;
  situation: string;
  workload_distribution: string | number;
  fullTimeWorker: string;
  partTimeWorker: string;
  childrenNo: string | number;
  adress: string;
};

export default function PreliminaryRemarkOutput({
  meet_year,
  date_of_moving_in,
  situation,
  workload_distribution,
  fullTimeWorker,
  partTimeWorker,
  childrenNo,
  adress,
}: PreliminaryRemarkOutputProps) {
  const { translate } = useLocales();

  return (
    <View style={styles.section2}>
      <Text
        style={{
          ...styles.title,
          marginBottom: 5,
        }}
      >
        1{' '}
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.preliminaryRemark.title'
          )
        )}
      </Text>
      <Text>
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.firstDefault',
            {
              meet_year,
              date_of_moving_in: fDate(date_of_moving_in, 'dd. MMM yyyy', 'de'),
              adress,
            }
          )
        )}{' '}
        {String(
          translate(
            'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.secondDefault'
          )
        )}
      </Text>
      {situation === 'both_employed_no_children' && <BothEmployedNoChildren />}
      {situation === 'one_full_time_one_part_time_no_children' && (
        <OneEmployedNoChildren
          fullTimeWorker={fullTimeWorker}
          workload_distribution={workload_distribution}
          partTimeWorker={partTimeWorker}
        />
      )}
      {situation === 'one_full_time_one_household_with_children' && (
        <OneEmployedWithChildren
          fullTimeWorker={fullTimeWorker}
          householder={partTimeWorker}
          childrenNo={childrenNo}
        />
      )}
    </View>
  );
}

const BothEmployedNoChildren = () => {
  const { translate } = useLocales();
  return (
    <Text>
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.bothEmployedNoChildren'
        )
      )}
    </Text>
  );
};

type OneEmployedNoChildrenProps = {
  fullTimeWorker: string;
  partTimeWorker: string;
  workload_distribution: string | number;
};

const OneEmployedNoChildren = ({
  fullTimeWorker,
  partTimeWorker,
  workload_distribution,
}: OneEmployedNoChildrenProps) => {
  const { translate } = useLocales();

  return (
    <Text>
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.oneWorksFullTime',
          {
            who_works_full_time: fullTimeWorker,
            who_works_part_time: partTimeWorker,
            workload_distribution,
          }
        )
      )}
    </Text>
  );
};

type OneEmployedWithChildrenProps = {
  fullTimeWorker: string;
  householder: string;
  childrenNo: string | number;
};

const OneEmployedWithChildren = ({
  fullTimeWorker,
  householder,
  childrenNo,
}: OneEmployedWithChildrenProps) => {
  const { translate } = useLocales();

  return (
    <Text>
      {String(
        translate(
          'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.oneFullTimeOneHousehold',
          {
            who_works_full_time: fullTimeWorker,
            householder,
            childrenNo:
              childrenNo === 1
                ? translate(
                    'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.singularChild'
                  )
                : translate(
                    'cohabitationAgreement.stepFour.document.preliminaryRemark.section1.plurarChildren'
                  ),
          }
        )
      )}
    </Text>
  );
};
